.fa-insights--panel{
    border: none;

    .fa-insights--panel-item{
        background-color: var(--color-white) !important;
        border-color: var(--color-gray-4);
        .ant-collapse-header{
            background-color: var(--color-white) !important;
        }
        .ant-collapse-content{
            border: none !important;
        }
        .ant-collapse-content > .ant-collapse-content-box{
            padding: 4px 0 16px 24px

        }
        .ant-collapse-content-active{
            .ant-collapse-content-box{
                &:empty{
                    padding: 0px !important;
                }
            }
        }
        &.ant-collapse-item-disabled {
            .ant-collapse-arrow{
                display: none;
            }
        }
    }

}


.fa-insights--panel .ant-collapse-item-disabled > .ant-collapse-header, .fa-insights--panel .ant-collapse-item-disabled > .ant-collapse-header > .arrow
{
cursor: auto;
}

.fa-tag--green
{
    background-color: rgba(#4DB976,0.2) !important;
}
.fa-tag--red{
background-color: rgba(#EA6262,0.2) !important;

}

.fa-tag--grey{
    background-color: var(--color-gray-4) !important;
}

.fa-tag--regular{
    background-color: transparent !important;
}

.fa-tag--green,
.fa-tag--red,
.fa-tag--grey{
    border: none;
}

.fa-tag--green,
.fa-tag--red,
.fa-tag--grey,
.fa-tag--regular{
    border-radius: 4px;
    color: var(--color-gray-10);
    margin: initial;
    margin-right: 4px;
    display: inline-flex !important;
    align-items: center;
    svg{
        margin-right: 4px;
    }
}

.fa-tag--highlight{
    color: var(--color-brand-6);
}

.fa-tag--small-grey{
        background-color: #D9D9D9;
        font-size: 10px;
        color: #777777;
        border-radius: 2px;
        padding: 0px 8px;
        margin: 0;
        line-height: 12px;
}
