.fa-aside {
  height: 100%;
  width: 64px;
  background-color: #f5f6f8;
  overflow: auto;
  height: calc(100vh - 56px);
  position: fixed;
  top: 56px;
  left: 0;
  // z-index: 1001;

  //hiding scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .ant-row {
    position: relative;
  }
  .fa-aside--divider {
    width: 40px;
    margin: 0 auto;
    height: 1px;
    background-color: var(--color-gray-7);
    opacity: 0.5;
  }

  a {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    opacity: 0.58;
    background-color: transparent;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 1;
      &:hover {
        background-color: transparent;
      }
      &:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 24px;
        left: 0px;
        top: 16px;
        background-color: var(--color-white);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    &:hover {
      background-color: var(--color-gray-6);
    }

    &.fa-brand-logo {
      &:hover {
        background-color: transparent;
      }
      &.active {
        &:before {
          display: none;
        }
      }
    }
  }

  .fa-aside--avatar {
    background-color: var(--color-gray-8);
    cursor: pointer;
  }
}

.fa-popupcard--wrapper {
  position: fixed;
  top: 48px !important;

  .ant-popover-inner {
    width: 356px;
    max-height: 640px;
    // border: 1px solid #f7f5f8;
    border-radius: 12px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-arrow {
    display: none;
  }

  .fa-popupcard {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    a {
      color: #0b1e39;
    }
    .fa-popupcard-divider {
      width: 100%;
      margin: 8px 0;
      border-bottom: thin solid var(--color-gray-4);
    }
    .project-item {
      border: 2px solid transparent;
      cursor: pointer;
      border-radius: 8px;
      width: 100%;
      padding: 6px 16px;
      margin: 2px 0;
      transition: all 0.3s;
      &:hover,
      &:focus-visible {
        background-color: var(--color-gray-4);
        outline: none;
      }
      &.active {
        // border: 2px solid var(--color-brand-6);
        // background-color: var(--color-gray-4);
        color: #1890ff;
      }
    }
  }

  .fa-project-list--wrapper {
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .fa-project-list--search {
    width: 340px;
    background-color: var(--color-gray-3);
    border: 1px solid var(--color-gray-2);
    padding: 0 0 0 12px;
    input {
      background-color: var(--color-gray-3);
      font-size: 14px;
      color: var(--color-charcoal-45);
      border-radius: 6px;
      border: none;
      height: 38px;
      padding: 0 8px;
      &:active,
      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
      &::placeholder {
        color: var(--color-charcoal-45);
      }
    }
  }
}

.fa-popupcard--wrapper--info {
  width: 320px;
}

.fa-tag--beta {
  border-radius: 4px;
  font-weight: 600;
  margin: 8px 0 0;
  font-size: 9px;
  line-height: 1.2;
  border-color: var(--color-volcano-4);
  color: var(--color-volcano-4);
}
