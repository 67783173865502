$cq-card-transition: transform 0.2s ease-in-out;

.fai--custom-card {
  background: linear-gradient(42.84deg, #eeedf8 15.47%, #c2e5ea 90.5%);
  border: thin solid transparent;
  border-radius: 12px;
  padding: 2em 1.5em;
  transition: $cq-card-transition;
  cursor: pointer;

  height: 200px;
  width: 200px;

  @media screen and (max-width: 1336px) {
    padding: 2em 0.8em;
    height: 180px;
    width: 180px;
  }

  .fai--custom-card--icon {
    margin: 1.5em 0 1.2em;
  }

  .before-hover {
    display: block;
  }
  .after-hover {
    display: none;
  }
  .fai--custom-card--icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fai--custom-card--cta {
    background-color: #34a7ba;
    color: var(--color-white);
    font-weight: $font-weight--bold;
    border-radius: 38px;
    padding: 6px 15px;
    font-size: 12px;
    line-height: 1;
    margin: 1em auto 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    svg {
      display: inline;
      margin-left: 0.2em;
    }
  }

  &:hover {
    transition: $cq-card-transition;
    background: var(--color-white);
    border: thin solid var(--color-gray-4);
    box-shadow: 0px 0px 8px var(--color-gray-4);

    .before-hover {
      display: none;
    }
    .after-hover {
      display: block;
    }
    .fai--custom-card--icon {
      margin: 0.2em 0 1em;
      svg {
        height: 35px !important;
        width: 35px !important;
      }
    }

    // .fai--custom-card--desc{
    //     transform: translateY(-50px);
    // }
    // .fai--custom-card--title
    // {
    //         transform: translateY(-50px);
    // }
    // .fai--custom-card--icon{
    //     svg{
    //         height: 44px !important;
    //         width: 44px !important;
    //     }
    // }
  }

  .fai--custom-card--desc,
  .fai--custom-card--title {
    letter-spacing: -0.02em;
    background: linear-gradient(42deg, #7a6dc9 15.46%, #5db9c8 75.92%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
  }
  .fai--custom-card--desc {
    text-transform: uppercase;
    font-size: 10px !important;
    line-height: 1.6;
    transition: $cq-card-transition;
  }
  .fai--custom-card--title {
    transition: $cq-card-transition;
  }
  .fai--custom-card--content {
    transition: $cq-card-transition;
    // height: 50px;
    // overflow: hidden;
    // display: block;
    // margin: 0 0 1.2em;
  }
}

.fa--query_block {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px 42px;
  position: relative;

  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &.no-padding {
    padding: 0;
  }

  &.no-padding-l {
    padding-left: 0;
  }

  &.no-padding-r {
    padding-right: 0;
  }

  &.no-padding-t {
    padding-top: 0;
  }

  &.no-padding-b {
    padding-bottom: 0;
  }

  &.bordered {
    // border-top: 1px solid var(--color-gray-4);
    border-bottom: 1px solid var(--color-gray-4);
  }

  &.borderless {
    border: none;
  }

  .ant-collapse-content {
    overflow: visible;
  }

  .ant-collapse-content-box {
    // padding-left: 0;
    // padding-right: 0;
    padding: 0;
  }

  .fa--query_block--actions-cols {
    margin-left: 8px;
    display: flex;
    .ant-btn.ant-btn.ant-btn-link {
      padding: 4px 6px;
    }
  }

  .fa--query_block--actions {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: none;
    transition: all 0.3s;
    .ant-btn.ant-btn.ant-btn-link {
      padding: 4px 6px;
    }
  }

  .fa--query_block--filters {
    padding-top: 8px;
    display: flex;
    border-left-width: 4px;
    border-left-color: #e6f7ff;
    row-gap: 8px;
  }
  // This removed Left Border from EventGroupBlock
  .fa--query_block--filters:has(.remove_left_border) {
    padding-top: 8px;
    border-left-color: transparent;
    border-left-width: 0px;
  }

  .fa--query_block--filters-values {
  }
  .fa--query_block--add-event {
    height: 24px;
    width: 24px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.3s;
    &.active {
      color: rgba(0, 0, 0, 0.65);
      background-color: rgb(240 240 240/var(--tw-bg-opacity));
    }
    &.inactive {
      border: 1px solid transparent !important;
    }
  }
  .fa--query_block--add-event-sm {
    @extend .fa--query_block--add-event;
    height: 16px;
    width: 16px;
    font-size: 10px;
  }
  .ant-btn.ant-btn.ant-btn-link:focus,
  .ant-btn.ant-btn.ant-btn-link:hover,
  .ant-btn.ant-btn.ant-btn-link:active {
    border-color: transparent;
  }
  .ant-select {
    .ant-select-selector {
      border-radius: 6px;
      height: 40px;
    }
    .ant-select-dropdown {
      border-radius: 6px;
    }
    &.no-ant-border {
      color: var(--color-brand-6);
      font-weight: $font-weight--regular;
      .ant-select-selector {
        height: auto !important;
        border: 0px solid transparent !important;
        padding: 0px !important;
      }
    }
    &:hover,
    &:active,
    &:focus {
      .ant-select-selector {
        border-color: transparent !important;
        border-right-width: 1px !important;
      }
    }
  }

  .fa_input_text_container {
    .ant-select-selector {
      height: 32px;
      line-height: 0;
      border-radius: 0;
      min-width: 100px;
    }
    .ant-select-selection-item {
      height: 24px;
      line-height: 22px;
    }
    .ant-select-selection-search {
      min-width: 32px;
    }
  }

  .fa-select--ghost.ant-select {
    .ant-select-selector {
      background: none !important;
      border: none !important;
      height: 28px !important;
      min-width: 100px;
    }

    &:hover,
    &:active,
    &:focus {
      border: none !important;
      outline: none;
      border-radius: 0;
    }
  }

  .ant-collapse {
    padding: 0;
    .ant-collapse-header {
      padding: 0;
      background: none;
    }
    .ant-collapse-item {
      border-bottom-color: transparent;
    }
    &.ant-collapse-borderless {
      background-color: transparent;
    }
  }
}

.fa--query_block_section {
  @extend .fa--query_block;

  &:hover {
    .fa--query_block--actions {
      display: block;
    }
  }
}
.fa--query_block_section--basic {
  &:hover {
    .fa--query_block--actions {
      display: block;
    }
  }
}

.fa-filter-select {
  .ant-input-affix-wrapper {
    border: none;
    margin: 0;
    overflow: scroll;
  }

  .ant-input-affix-wrapper-focused {
    outline: none;
    box-shadow: none;
  }
}
.fa-event-popover {
  background-color: pink;
}

.fapp-date-picker {
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background: white;
  border: 2px solid #eff2f7;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 4px;
}

.ant-popover-inner {
  // display: contents;
}

.fai--custom-card-new {
  border: 1px solid var(--color-gray-4);
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
  width: 220px;
  margin-left: 1.2em;
  // overflow: hidden;
  &:first-child {
    margin-left: 0px;
  }

  &:hover {
    box-shadow: 6px 7px 17px var(--color-gray-4);
  }
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    box-shadow: none !important;
    opacity: 0.7;
  }
  .fai--custom-card-new--top-section {
    background-color: var(--color-gray-4);
    border-bottom: 1px solid var(--color-gray-4);
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 2em 0;
    position: relative;
  }
  .fai--custom-card-new--bottom-section {
    padding: 12px 16px;
    min-height: 100px;
    .fai--custom-card-new--desc {
      font-size: 12px;
      line-height: 1.4;
    }
  }
}

.fai--custom-card--badge {
  position: absolute;
  border-radius: 4px;
  // top:-12px;
  // left: 50%;
  // transform: translateX(-50%);
  top: -10px;
  right: 8px;
}
.fa-template--card {
  transition: all 0.3s;
  .fai--custom-card--badge {
    opacity: 0;
  }
  &:hover {
    background-color: rgba(var(--color-gray-4), 0.7);
    .fai--custom-card--badge {
      opacity: 1;
    }
  }
}

.fa-query-edit {
  .ant-collapse {
    background-color: none !important;
  }

  .ant-collapse-header {
    padding-left: 2rem !important;
    // background: #F8F8FC;
    margin: 0 -2rem;
  }

  .ant-collapse-content {
    overflow: visible;
    padding: 0 30px;
  }

  .ant-collapse-content-box {
    padding-left: 0 !important;
  }
}

// .query-open {
//     .header {
//         .ant-collapse-header {
//         display: none !important;
//         }
//     }
// }

.query_header_icon {
  position: absolute;
  right: 2.5rem;

  &:hover {
    border-radius: 50%;
    background: white;
  }
}

#query_header {
  .ant-collapse-header:first-of-type {
    background: none;
    border: none;
    border-radius: 0;
  }

  .query_header_icon {
    display: none;
    border-radius: 50%;
    background: white;
  }
}

.fa-queryBuilder-container {
  position: absolute;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: white;
  margin-left: -64px;
}

.query_card_cont {
  position: relative;
  padding: 0px 32px;
  // padding-bottom: 60px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  transition: 0.1s ease;
  overflow: hidden;
}

.query_card_close {
  height: 186px;

  .query_card_expand {
    position: absolute;
    right: 32px;
    bottom: 32px;
    z-index: 10;
  }

  .query_composer {
    pointer-events: none;
  }
}

.query_card_close:hover {
  cursor: pointer;
}

.query_card_close::after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
  width: 100%;
  height: 8em;
  border-bottom: 1px solid #dbdbdb;
}

.query_card_open {
  height: 100% !important;
  overflow: visible;

  .query_card_expand {
    display: none;
  }
}

.fa-btn--custom {
  padding: 4px;
  height: 32px;
  width: 32px;
}

.fa-truncate-150 {
  max-width: 150px;
  overflow: hidden;
}

.btn-left-round {
  border-radius: 100px 0 0 100px !important;
}
.btn-right-round {
  border-radius: 0 100px 100px 0 !important;
}
.btn-total-round {
  border-radius: 100px !important;
}
.filter-buttons-radius {
  border-radius: 0px !important;
}
.filter-buttons-margin {
  margin-right: 2px;
}
.filter-remove-button {
  background: #f5f6f8 !important;
}
.filter-remove-button:hover {
  background: #e7e9ed !important;
}
.plus-button {
  margin-left: 8px;
  padding-top: 1px;
}
.date-input-number {
  margin-left: 2px;
  color: #1e89ff;
}

.breakdown-margin {
  margin-right: 22px;
}

.input-value {
  font-family: $font-family;
  border-radius: 4px;
  border-color: transparent;
  background: var(--color-gray-4);
  color: var(--color-gray-8);
  margin-right: 4px;
}
.input-value:hover:active:focus {
  border-color: var(--color-brand-6);
}
