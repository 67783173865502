.border--thin{
    border: thin solid var(--color-gray-4);
}
.border--thin-2{
    border: thin solid var(--color-gray-4);
}
.border--thin-2--transparent{
    border: thin solid transparent;
}
.border--thin-2--brand{
    border: thin solid var(--color-brand-6);
}

.border-bottom--thin{
    border-bottom: thin solid var(--color-gray-4);
}
.border-bottom--thin-2{
    border-bottom: thin solid var(--color-gray-4);
}
.border-bottom--thin-3{
    border-bottom: thin solid var(--color-gray-6);
}
.border-top--thin{
    border-top: thin solid var(--color-gray-4);
}
.border-top--thin-2{
    border-top: thin solid var(--color-gray-4);
}
.border-right--thin{
    border-right: thin solid var(--color-gray-4);
}
.border-right--thin-2{
    border-right: thin solid var(--color-gray-4);
}
.border-right--thin-3{
    border-right: thin solid var(--color-gray-6);
}
.border-left--thin{
    border-left: thin solid var(--color-gray-4);
}
.border-left--thin-2{
    border-left: thin solid var(--color-gray-4);
}
.border-radius--medium{
    border-radius: 8px;
}
.background-color--brand-color-1{
 background-color: var(--color-brand-1);
}

.background-color--white{
 background-color: #fff;
}
.background-color--mono-color-1{
 background-color: var(--color-gray-4);
}
.background-color--mono-color-2{
 background-color: var(--color-gray-4);
}

.border-radius--sm
{
    border-radius: 4px;
}
.border-radius--lg
{
    border-radius: 12px;
}

.border-left--blue-color-thick{
    border-left-color: var(--color-blue-5);
    border-left-width: 3px;
    border-left-style: solid;
}
