@import '../components/_scrollbar.scss';

.list-container {
  padding: 40px;
  width: 100%;
  height: 100%;
  overflow: auto;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.fa-activity--filter {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 0 !important;

  .ant-popover-inner {
    width: 324px;
    max-height: 500px;
    border: 1px solid #f7f5f8;
    border-radius: 12px;

    .ant-popover-inner-content {
      padding: 24px;

      .ant-tabs {
        .ant-tabs-nav {
          margin: 0 0 4px 0;
        }

        .ant-tabs-content-holder {
          .ant-tabs-content {
            .ant-tabs-tabpane {
              .ant-input-affix-wrapper {
                padding: 6px 12px;
                height: 36px;
                background: #f5f6f8;
                border: 1px solid #e7e9ed;
                border-radius: 6px;
                box-shadow: none;
                transition: all 0.2s;

                &:hover {
                  border: 1px solid var(--color-gray-6);
                  cursor: pointer;
                }

                .ant-input {
                  background-color: transparent;
                }
              }

              .fa-custom--popover-content {
                div {
                  max-height: 392px;
                  overflow-x: hidden;

                  &.apply_active {
                    max-height: 348px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-popover-arrow {
    display: none;
  }
}

.fa-activity-filter--tabname {
  font-weight: 500;
  font-size: 14px;
}

.fa-timeline {
  position: relative;
  height: calc(100vh - 56px);
  overflow: hidden;

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    height: 56px;
    background-color: var(--color-white);
    z-index: 20;
    border-bottom: 1px solid rgb(231, 233, 237);
    box-shadow: 0px 0px 8px rgba(var(--color-gray-4), 1);
  }

  &--content {
    display: flex;
    align-items: stretch;
  }
}

.leftpane {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 20%;
  min-width: 300px;
  background: #fff;
  padding: 0;
  border-right: 1px solid #e7e9ed;
  height: calc(100vh - 56px);
  z-index: 15;

  .header {
    position: sticky;
    background-color: #fff;
    padding: 88px 32px 16px;
    z-index: 0;
    width: 100%;

    .user {
      .avatar {
        display: flex;
        align-items: center;
        color: #fff;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
      }
    }

    .account {
      width: 100%;
      border-bottom: 1px solid #f0f0f0;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f0f0f0;
        width: 32px;
        height: 32px;
        border-radius: 8px;
      }
    }
  }

  .props {
    flex-grow: 1;
    margin: 0;
    padding: 8px 0;
    max-height: calc(100% - 336px);
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .add-prop-btn {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding: 8px 32px 24px;

    &.with-attr {
      bottom: 32px;
    }
  }

  .logo_attr {
    position: fixed;
    top: calc(100% - 32px);
    left: 0;
    padding: 4px 32px;
    background-color: white;
    white-space: nowrap;
    font-size: 12px;
    line-height: 24px;
  }
}

.leftpane-prop {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 8px;

  &.pl-8 {
    padding-left: 32px;
  }

  .del-button {
    visibility: hidden;
  }

  &:hover {
    background-color: #f5f6f8;

    .del-button {
      visibility: visible;
      background-color: #f5f6f8;
    }
  }
}

.timeline-view {
  flex: 1 1 auto;
  width: 80%;
  height: 100%;
  padding: 80px 32px 32px;
  overflow: hidden;
}

.timeline-view--tabs {
  .ant-tabs-nav {
    margin: 0;
  }
}

.tl-actions-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
}

.collapse-btns {
  display: flex;
  align-items: center;

  &--btn {
    &:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.birdview-container {
  max-height: calc(100vh - 272px);
  overflow: auto;

  table {
    border-collapse: separate;
    border-spacing: 0;
    table-layout: auto;
    width: 100%;

    th {
      min-width: 175px;
      padding: 0 16px;
      border-bottom: 1px solid #e7e9ed;
      vertical-align: middle;
      background: #f5f6f8;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 9;
      height: 58px;

      &:first-child {
        width: 175px !important;
        left: 0;
        border-right: 2px solid #e7e9ed;
        z-index: 10;

        &.single-user {
          width: 250px !important;
        }
      }
    }

    tbody {
      td {
        position: relative;
        border-bottom: 1px solid #e7e9ed;
        vertical-align: top;
        background-color: #fff;
        background: linear-gradient(#bae7ff 0 0);
        background-repeat: no-repeat;
        background-size: 2px 100%;

        &.bg-none {
          background: none;
        }

        &.bg-gradient--44px {
          background-position: 40px;
        }

        &.bg-gradient--120px {
          background-position: 108px;
        }

        &:first-child {
          left: 0;
          border-right: 2px solid #e7e9ed;
          position: -webkit-sticky;
          position: sticky;
          z-index: 8;
          background: #fff;
          line-height: 58px;

          .timestamp {
            padding: 0 16px;
            position: -webkit-sticky;
            position: sticky;
            width: 136px !important;

            &.top-40 {
              top: 40px;
            }

            &.top-64 {
              top: 64px;
            }
          }
        }
      }
    }
  }
}

.milestone-section {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
}

.green-stripe {
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #f1f9f4;

  &.opaque {
    background: #5aca89;
    opacity: 0.1;
  }

  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 36px;
    color: #5aca89;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.02, 1);
      background: #f1f9f4;
      overflow: visible;
      text-overflow: unset;
      width: fit-content;
      height: auto;
    }
  }
}

.birdview-events {
  min-width: 300px;
  max-width: 400px;

  &.user-pad {
    padding: 16px 96px;
  }

  &.account-pad {
    padding: 16px 28px;
  }

  &--collapsed {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 4px;
  }

  &--expanded {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  &__event {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 4px;

    .icon {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 50%;
    }

    .tag {
      color: #3e516c;
      width: fit-content;
      max-width: 300px;
      height: 24px;
      background: #f5f6f8;
      border-radius: 4px;
      padding: 1px 12px;
      align-items: center;
    }
  }

  &__num {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    color: #3e516c;
    width: fit-content;
    height: 24px;
    background: #f5f6f8;
    border-radius: 4px;
    padding: 3px 8px;
    color: #1890ff;
    cursor: pointer;
  }
}

.user-card--wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 42px;
  width: 100%;
  padding: 0 12px 0;
  background: #ffffff;
  z-index: 5;

  .user-card {
    position: relative;
    width: 90%;
    left: 9%;
    padding: 16px 20px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    border-radius: 4px;

    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #3e516c;
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
  }
}

.user-timeline--events {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    background: #bae7ff;
    top: 0;
    bottom: 0;
    left: 20%;
    margin-left: -1px;
  }
}

.timeline-event__container {
  padding: 16px 32px;
  position: relative;
  background: inherit;
  width: 75%;
  left: 20%;

  &::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 1px;
    top: 48px;
    background: #bae7ff;
    z-index: 1;
    left: 8px;
  }

  .timestamp {
    position: absolute;
    display: inline;
    font-size: 14px;
    font-weight: bold;
    color: #8692a3;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 1;
    top: 38px;
    left: -100px;
  }

  .event-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: 32px;
    left: -16px;
    height: 32px;
    width: 32px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background-color: #f9f9f9;
  }

  .card {
    padding: 16px 20px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
}

.top-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .heading {
    width: 80%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .heading-with-sub {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .sub {
      width: 100%;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .main {
      width: 100%;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .source-icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    border: 1px solid;
    border-color: #fafafa;
    border-radius: 50%;
  }
}

.dropdown-btn {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  &.large {
    height: 40px;
  }
}

.top-8 {
  top: 2rem;
}

.divider-margin {
  margin: 0;
  margin-bottom: 5px;
}

.font-size--small {
  font-size: 12px;
}

.fa-custom-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  text-align: left;

  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    }
  }
}

.inline-flex {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 100%;

  &.gap--4 {
    gap: 4px;
  }

  &.gap--6 {
    gap: 6px;
  }

  &.gap--8 {
    gap: 8px;
  }

  &.gap--12 {
    gap: 12px;
  }
}

.event-name--sm {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userlist-avatar {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
}

.fa-table--profileslist {
  padding: 0;

  & .ant-table-container {
    margin: 0 0 0 32px;
  }

  & .ant-table-content {
    border: 1px solid var(--color-gray-4);
    border-radius: 8px 8px 0 0;
    max-height: calc(100vh - 13.5rem);
    overflow: auto !important;
    background-color: var(--color-gray-2);
    @include scrollbar-styles;
    overscroll-behavior: auto;
  }

  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 10000;
    .ant-table-cell {
      font-weight: $font-weight--medium;
      color: var(--color-gray-8);
      background-color: var(--color-gray-2);
    }

    .ant-table-column-sort,
    .ant-table-column-has-sorters {
      background-color: var(--color-gray-2);
    }

    .ant-table-column-sorters {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        border-bottom: 1px solid var(--color-gray-4);
        font-weight: $font-weight--regular;
        font-size: 14px;
        line-height: 24px;
        padding: 8px 16px;
        color: var(--color-gray-10);
      }
    }
  }

  .ant-table-column-sort {
    background-color: white;
  }

  .ant-table-pagination {
    position: fixed;
    bottom: 0;
    background: white;
    padding: 8px 16px;
    margin: 0;
    width: 100%;
    border-top: 1px solid var(--color-gray-4);
  }

  & table {
    min-width: unset !important;

    & th {
      padding: 0 !important;
      height: 48px !important;
    }

    & tr {
      height: 48px !important;
      background-color: white;

      &:first-child {
        height: 0 !important;
      }

      &.active {
        td {
          background-color: #fafafa !important;
        }
      }

      a {
        color: unset;

        &:hover {
          color: #1890ff;
          text-decoration: underline;
          text-decoration-color: #1890ff;
        }
      }

      .preview-btns {
        display: none;
        float: right;
        gap: 4px;
        background-color: #fafafa !important;
        cursor: pointer;
        transition: all 0.3s;
      }

      &:hover {
        td {
          background-color: #fafafa !important;

          &:hover {
            a {
              color: #1890ff;
              text-decoration: underline;
              text-decoration-color: #1890ff;
            }
          }

          .preview-btns {
            display: inline-flex;
          }
        }
      }
    }
  }
}

.line-height-8 {
  line-height: 32px;
}

.static-button {
  align-items: center;
  background-color: #f5f6f8;
  font-weight: bold;
  font-size: 14px;
  color: grey;
  height: 32px;
  border: none;
  cursor: default;

  &:focus,
  &:hover,
  &:active,
  &:visited {
    background-color: #f5f6f8;
  }
}

.pb-30 {
  padding-bottom: 7.5rem;
}

.pb-50 {
  padding-bottom: 12.5rem;
}

.segment-modal {
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 56px;
}

.segment-query_container {
  padding: 16px 32px;
  border: 1px solid #dbdbdb;
  border-radius: 5px !important;
  min-height: 100px;
}

.segment-query_section {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 16px;
}

.segment-query_block {
  display: flex;
  width: 100%;
  align-items: flex-start;

  .title {
    width: 140px;
    line-height: 32px;
    white-space: nowrap;
    margin: 0;
    margin-right: 16px;
    text-align: right;

    &.width-unset {
      width: fit-content;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.segment-modal_footer {
  background-color: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 24px 40px 40px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.search-btn {
  padding: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
}

.engagement-tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  align-items: center;
  padding: 2px 14px;
  gap: 6px;
  background: var(--bg-color);
  border-radius: 16px;
}
.overview-container {
  max-height: calc(100vh - 208px);
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .overview {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 16px 0;

    .top-metrics {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      .metric {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1 1 auto;
        height: 96px;
        width: calc(25% - 16px);
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
      }
    }

    .summary {
      border: 1px solid #f0f0f0;
      border-radius: 8px;

      .heading {
        display: flex;
        padding: 16px;
        align-items: center;
        height: 48px;
        border-bottom: 1px solid #f0f0f0;
      }
    }

    .trend {
      border: 1px solid #f0f0f0;
      border-radius: 8px;

      .heading {
        display: flex;
        padding: 16px;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #f0f0f0;
      }

      .chart {
        padding: 16px 24px 8px 8px;
      }
    }

    .top-tables {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      gap: 20px;

      .top-table-container {
        flex: 1 1 auto;
        max-width: calc(50% - 10px);
        max-height: 288px;
        border: 1px solid #f0f0f0;
        border-radius: 8px;

        .heading {
          display: flex;
          padding: 16px;
          align-items: center;
          height: 48px;
          border-bottom: 1px solid #f0f0f0;
          background-color: transparent;
        }

        .content {
          width: 100%;
          padding: 0;

          .ant-table-thead {
            .ant-table-cell {
              font-weight: $font-weight--medium;
              font-size: 12px;
              color: var(--color-gray-8);
              padding: 8px 12px;
              white-space: nowrap;
            }
          }

          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                border: none;
                font-weight: $font-weight--regular;
                font-size: 12px;
                line-height: 24px;
                padding: 12px;
                color: var(--color-gray-10);
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}

.logo-attrib {
  position: fixed;
  text-align: right;
  bottom: 12px;
  right: 64px;
}

.timeline-table-container {
  max-height: calc(100vh - 232px);
  overflow-y: auto;

  &.custom-height {
    max-height: calc(100vh - 176px);
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #e7e9ed;

    td {
      padding: 12px;
      text-align: left;
    }

    .timestamp-row {
      position: sticky;
      top: 0;
      background: #f5f6f8;
      height: 40px;
      z-index: 1;

      td {
        font-weight: 600;
        color: #000000a6;
      }
    }

    .table-row {
      display: flex;
      align-items: center;
      border-top: 1px solid #e7e9ed;
      height: 56px;

      .fixed-cell {
        flex-shrink: 0;
        flex-grow: 0;
      }

      .timestamp-cell {
        width: 12%;
        color: #00000073;
        white-space: nowrap;
      }

      .event-cell,
      .user-cell {
        width: 24%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        overflow: hidden;
        gap: 8px;

        .text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #000000a6;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .properties-cell {
        padding: 8px 12px;
        width: 40%;
        display: flex;
        flex-direction: column;

        .propkey {
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.01em;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .propvalue {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .clickable {
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

.event-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--icon-size, 24px) !important;
  width: var(--icon-size, 24px) !important;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #f9f9f9;

  img {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
  }
}

.fa-event-drawer--right {
  &.ant-drawer {
    top: 56px;
  }

  .ant-drawer-content-wrapper {
    width: 30% !important;
  }

  .ant-drawer-header {
    padding: 12px 24px;
  }
}

.text-with-tooltip {
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &--multiline {
    @extend .text-with-tooltip;
    white-space: normal;
    --max-lines: 2;
    max-height: var(--max-lines);
    display: -webkit-box;
    -webkit-line-clamp: var(--max-lines);
    -webkit-box-orient: vertical;
  }
}

.fa-account-drawer--right {
  &.ant-drawer {
    top: 56px;
  }

  &.ant-drawer-right.ant-drawer-open {
    width: 60% !important;
  }

  .ant-drawer-header {
    padding: 12px 24px;
  }

  .ant-drawer-body {
    padding: 16px 24px;
  }
}

.event-infocard--row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  .prop {
    color: var(--color-gray-7);
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    margin-right: 8px;
    max-width: 60%;
  }

  .value {
    color: var(--color-gray-8);
    line-height: 24px;
    text-align: right;
    max-width: 60%;
  }
}

.event-drawer-items {
  max-height: calc(100vh - 312px);
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.see-more-section {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  box-shadow: 0px -16px 32px 16px rgba(255, 255, 255, 1);
  bottom: 56px;
  background: white;
}

.bordered-gray {
  &--box {
    border: 1px solid #e7e9ed;
  }

  &--top {
    border-top: 1px solid #e7e9ed;
  }

  &--right {
    border-right: 1px solid #e7e9ed;
  }

  &--left {
    border-left: 1px solid #e7e9ed;
  }

  &--bottom {
    border-bottom: 1px solid #e7e9ed;
  }
}

.infocard-popover {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    width: 448px;
    padding: 16px 24px;
    border: 1px solid #f7f5f8;
    border-radius: 8px;

    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

.event-name-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 8px;
  padding-right: 0;

  .heading {
    width: 320px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .heading-with-sub {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .sub-heading {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    }

    .main {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .source-icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    border: 1px solid;
    border-color: #fafafa;
    border-radius: 50%;
  }
}

.properties-section {
  overflow-y: auto;
  max-height: 240px;
  padding: 0 8px;
}

.scroll-shadows {
  scrollbar-width: thin;
  background: linear-gradient(to top, white, white),
    linear-gradient(to top, white, white),
    linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
  background-position:
    bottom center,
    top center,
    bottom center,
    top center;
  background-color: white;
  background-repeat: no-repeat;
  background-size:
    100% 16px,
    100% 16px,
    100% 8px,
    100% 8px;
  background-attachment: local, local, scroll, scroll;
}

.bg-active-row {
  background-color: var(--color-gray-2);
}

.active-link {
  a {
    color: #1890ff !important;
    text-decoration: underline !important;
    text-decoration-color: #1890ff !important;
  }
}

.border-neutral-100 {
  border-color: #f5f5f5;
}

.border-neutral-grey-4 {
  border-color: var(--color-gray-4);
}

.accounts-loader-div {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.vertical-separator {
  width: 1px;
  height: 100px;
  background: #333;
  margin: 0 20px;
}

.horizontal-separator {
  width: 100%;
  height: 1px;
  background: #333;
  margin: 20px 0;
}

.profiles-header {
  position: sticky;
  top: 0;
  background: linear-gradient(
    to right,
    transparent 0px,
    transparent 16px,
    white 16px,
    white 100%
  );
  max-width: 100%;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 8px 32px;
  border-bottom: 1px solid var(--color-gray-4);
  z-index: 99;
}

.button-shadow {
  box-shadow: 0px 2px 0px 0px #0000000d;
}

.preview-info {
  display: flex;
  padding: 8px 16px;
  gap: 8px;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 6px;
  line-height: 24px;
  min-height: 40px;

  svg {
    height: 24px;
    width: 24px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.editable-title {
  margin: 0;
  white-space: nowrap;
  overflow-x: auto;

  &.ant-typography-edit-content {
    left: 0;
    margin: 0;
    max-height: 40px;
  }

  textarea.ant-input {
    width: 344px;
    max-height: 32px !important;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
}

.local-header {
  position: sticky;
  top: 0;
  margin: -16px -32px 0;
  background: white;
  width: calc(100% + 64px);
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 32px;
  border-bottom: 1px solid var(--color-gray-4);
  z-index: 99;
}

.local-footer {
  background: var(--color-gray-4);
  padding: 24px;
  margin: 32px 0 0;
  width: 100%;
}

.account-summary {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: 960px;

  &.collapsed {
    max-height: 128px; // Collapsed state height
  }

  .expandable-content {
    transition: opacity 0.3s ease-in-out;

    .collapsed & {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.toggle-summary {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  cursor: pointer;
  
  transition: all 0.3s ease-in-out;

  &.expanded {
    border-top: 1px solid #f0f0f0;
    box-shadow: none;
  }

  &.collapsed {
    border: none;
    box-shadow: 0px -24px 24px 0px rgba(255, 255, 255, 1);

  }
}
