// SCSS folder structure - https://sass-guidelin.es/#architecture
// Author: Vishnu Baliga
// scss/
// |
// |– abstracts/
// |   |– _variables.scss    # Sass Variables
// |   |– _functions.scss    # Sass Functions
// |   |– _mixins.scss       # Sass Mixins
// |   |– _placeholders.scss # Sass Placeholders
// |
// |– base/
// |   |– _reset.scss        # Reset/normalize
// |   |– _typography.scss   # Typography rules
// |   …                     # Etc.
// |
// |– components/
// |   |– _buttons.scss      # Buttons
// |   |– _carousel.scss     # Carousel
// |   |– _cover.scss        # Cover
// |   |– _dropdown.scss     # Dropdown
// |   …                     # Etc.
// |
// |– layout/
// |   |– _navigation.scss   # Navigation
// |   |– _grid.scss         # Grid system
// |   |– _header.scss       # Header
// |   |– _footer.scss       # Footer
// |   |– _sidebar.scss      # Sidebar
// |   |– _forms.scss        # Forms
// |   …                     # Etc.
// |
// |– pages/
// |   |– _home.scss         # Home specific styles
// |   |– _contact.scss      # Contact specific styles
// |   …                     # Etc.
// |
// |– themes/
// |   |– _theme.scss        # Default theme
// |   |– _admin.scss        # Admin theme
// |   …                     # Etc.
// |
// |– vendors/
// |   |– _bootstrap.scss    # Bootstrap
// |   |– _jquery-ui.scss    # jQuery UI
// |   …                     # Etc.
// |
// `– main.scss              # Main Sass file

@import '/vendors/index';
@import 'abstracts/index';
@import 'generated-tokens/css/variables'; // CSS Variables from style-dictionary
@import 'base/index';
@import 'layout/index';
@import 'components/index';

@import '~antd/lib/style/index';

a,
.ant-typography a {
  transition: all 0.3s;
  color: var(--color-brand-6);
  &:hover,
  &:active,
  &:focus {
    color: var(--color-gray-12);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blueFadeOutHighlight {
  0% {
    background: #e6f7ff;
  }

  100% {
    background: #fff;
  }
}

.fa-container {
  // padding: 0 40px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  // max-width: 1280px;
  // min-width: 1200px;
  max-width: 1600px;
  overflow-x: hidden;

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  // -webkit-animation-fill-mode: both;
  // animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fa-page-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: auto;
}
.ant-typography > .anticon {
  display: inline-flex !important;
}

.ant-row.ant-form-item {
  margin: 0px !important;
}


.grecaptcha-badge { visibility: hidden; }