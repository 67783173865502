.fa-six-signal-panel {
  border: none;

  .fa-six-signal-panel-item {
    background-color: var(--color-white) !important;
    border-color: var(--color-gray-4);
    .ant-collapse-header {
      background-color: var(--color-white) !important;
      padding: 0px !important;
    }
    .ant-collapse-content {
      border: none !important;
      padding: 0px !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px !important;
    }
    .ant-collapse-content-active {
      .ant-collapse-content-box {
        &:empty {
          padding: 0px !important;
        }
      }
    }
    &.ant-collapse-item-disabled {
      .ant-collapse-arrow {
        display: none;
      }
    }
  }
}
