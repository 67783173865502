.fa-settings--menu {
    padding-right: 1.5em;
    border-color: var(--color-gray-4);
    height: 100%;
    width: 100%;

    .ant-menu-item.ant-menu-item-only-child {
        font-weight: $font-weight--bold;
        font-size: 16px;
        color: var(--color-gray-8);
        padding: auto 1em;

        &:hover {
            color: var(--color-gray-8);
        }

        &::after {
            // border-color: var(--color-brand-6);
            display: none;
        }

        &.ant-menu-item-selected {
            background-color: var(--color-gray-4);
            border-radius: 8px;
            color: var(--color-brand-6);

        }
    }
}

.dropdown-menu {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    width: 250px;
    padding: 0.25rem 0;
    font-size: 16px;
}

.dropdown-menu-item {
    padding: 0.75rem 1rem;
}
