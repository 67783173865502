.ant-tabs {
  &.ant-tabs-top {
    .ant-tabs-tab-btn {
      font-weight: $font-weight--regular;
      color: var(--color-gray-8);
      font-size: 16px;
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--color-brand-6);
      }
    }

        .ant-tabs-ink-bar {
            background: var(--color-brand-6);
        }
        .ant-tabs-tab
        {
            &.ant-tabs-tab-disabled{
                // background-color: var(--color-gray-4);
                opacity: 0.3;
            }
        }

  }
}

.ant-tabs.fa-tabs--dashboard {



  &.ant-tabs-top {
    .ant-tabs-nav-wrap {
      padding: 0 3em;
      flex: none !important;
    }

    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-content-holder {
      background-color: var(--color-gray-4);
      // height: unquote("-webkit-calc(100vh - 120px)");
      // height: unquote("calc(100vh - 110px)");
      // overflow-y: scroll;
    }

    .ant-tabs-tab {
      padding: 0.5rem;
      margin: 0;
      margin-right: 16px;
    }

    .ant-tabs-tab-active {
      span.ant-select-selection-item {
        color: var(--color-gray-8);
      }
    }

    .ant-tabs-tab-btn {
      font-weight: $font-weight--bold;
      color: var(--color-gray-7);
      font-size: 14px;
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--color-gray-8);
      }
    }

    .ant-tabs-ink-bar {
      background: var(--color-brand-6);
      height: 4px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  &.fa-tabs--no-padding{
    .ant-tabs-nav-wrap {
      padding: 0;
      flex: none !important;
    }
}

}
.ant-tabs.fa-tabs--dashboard.fa-tabs--white{
  .ant-tabs-content-holder {
    background-color: var(--color-white);
  }

}
