.ant-switch{
    background-color: var(--color-gray-6);
    border-color: var(--color-brand-6);
    &.ant-switch-checked{
        background-color: var(--color-brand-6);
    }
    .ant-switch-inner
    {
        font-family: $font-family;
        font-size: 9px;
        font-weight: $font-weight--bold;
        text-transform: uppercase;
    }

}
