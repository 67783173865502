.fa-custom-datepicker
{
    position: relative;
    button{
        transition: all 0.3s;
        display: flex;
        align-items: center;
    }
}
.fa-custom-datepicker--dropdown
{
    .ant-dropdown-menu
    {
        padding: 1em 0.7em;
        border-radius: 5px;
        .ant-dropdown-menu-item
        {
            border-radius: 10px;
            padding: 0.5em 1.2em;
            min-width: 180px;
        }
    }
}
.fa-custom-datepicker--parent
{
    .ant-dropdown-menu
    {
        // padding: 1.2em 1em;
        border-radius: 5px;

        .ant-dropdown-menu-item
        {
            border-radius: 0px;
            padding: 0.8em 1.2em;
            min-width: 325px !important;
        }
    }
}
.fa-custom-datepicker--datepicker
{
    z-index: 10000 !important;
    .ant-picker-panel-container
    {
        border-radius: 10px;
        .ant-picker-cell-today
        {
            .ant-picker-cell-inner{
                &:before{
                    border: 1px solid transparent;
                    background-color: rgba(var(--color-brand-2), 0.8);
                    z-index: -1;
                }
            }
        }
    }
}

.datepicker-minWidth{
    min-width: 100px;
    transition: all 0.3s;
}

.fa-date-picker {
    height: 100%;
}
