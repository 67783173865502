.rdrStaticRanges {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.rdrStaticRange {
    min-width: 140px;
}

.rdrStaticRange:hover {
    background: #F5F6F8;
}

.rdrStaticRangeSelected {
    background: #F5F6F8;
}

.rdrCalendarWrapper {
    width: 500px;
    padding: 25px;
}

.rdrMonth {
    width: auto;
}

.rdrMonthAndYearWrapper {
    padding: 15px;
}

.rdrMonthAndYearPickers {
    select {
        font-weight: bold;
    }
}

.rdrDay {
    justify-content: center;
    display: flex;
}

.rdrDayNumber {
    width: 100%;
    
    span {
        display: inline-block;
        padding: 10px 0;
        width: 40px
    }
}


.rdrInRange + .rdrDayNumber {
    span {
        color: #5949BC;
    }
}

.rdrStartEdge + .rdrDayNumber {
    
    span {
        background: #5949BC;
        color: white;
        border-radius: 25px;
    }
    
}

.rdrDayHovered{
    .rdrDayNumber {
        span {
            background: #5949BC;
            color: white;
            border-radius: 25px;
        }
    }
    
}

.rdrEndEdge {
    @extend .rdrStartEdge;
}

.rdrInputRanges {
    display: none;
}