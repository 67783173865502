.fa-code-block {
  background-color: var(--color-gray-4);
  padding: 1em;
  border-radius: 8px;
  white-space: pre-line;
}
.fa-code-code-block {
  color: #0e2647;
  font-family: 'IBM Plex Mono' !important;
}

.fa-intergration-card {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-4);
  width: 100%;
  margin: 0 15px 15px 0;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 0px 20px var(--color-gray-4);
  }
}

.fa-warning {
  padding: 9px 16px;
  margin: 16px 0px;
  width: 100%;
  font-size: smaller;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fa-login-security-card {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-4);
  width: 100%;
  margin: 0 15px 15px 0;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    & > div:nth-child(2) {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

.fa-settings-tabs {
  overflow: unset;
}

.fa-freq-cap-drawer--right {
  &.ant-drawer {
    top: 56px;
  }
  .ant-drawer-content-wrapper {
    width: 65% !important;
  }

  .ant-drawer-header {
    padding: 12px 24px;
  }
}

.fa-freq-cap-logs-drawer--right {
  &.ant-drawer {
    top: 56px;
  }
  .ant-drawer-content-wrapper {
    width: 50% !important;
  }

  .ant-drawer-header {
    padding: 12px 24px;
  }
}

.fa-freq-cap-drawer-table {
  padding: 0;

  .ant-table-pagination {
    position: fixed;
    z-index: 10;
    bottom: 0;
    background: white;
    padding: 8px 16px;
    margin: 0;
    width: 100%;
    border-top: 1px solid var(--color-gray-4);
  }
}
