.fa-custom-badge
{
    .ant-badge-status-dot{
        // height: 10px;
        // width: 10px;
    }
    &.fa-custom-badge--orange{
        .ant-badge-status-dot{
            background-color: var(--color-chart-6) !important;
            &:after{
                border: 1px solid var(--color-chart-6) !important;
            }
        }
    }
    &.fa-custom-badge--green{
        .ant-badge-status-dot{
            background-color: var(--color-chart-4) !important;
        }
    }
}
