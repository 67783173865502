.ant-layout-header--custom {
    background: var(--color-white);
    line-height: unset;
}

.fai--global-search {

    background: var(--color-white);
    border: 2px solid var(--color-gray-6);
    border-radius: 38px;
    width: 313px;
    height: 40px;
    outline: none;
    padding: 1em;
    text-align: center;
    color: var(--color-gray-10);
    font-weight: $font-weight--regular;
    font-size: 14px;
    transition: all 0.3s;

    &:focus {
        border-color: var(--color-gray-8);
    }

    &::placeholder {
        color: var(--color-gray-6);
    }
}
