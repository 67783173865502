.ant-radio-wrapper
{
    // background-color: var(--color-gray-6);
    .ant-radio{
        &:hover,&:active,&:focus{
            .ant-radio-inner{
                border-color: var(--color-brand-6);
            }

        }
        &.ant-radio-checked
        {
            &::after{
                border-color: var(--color-brand-6);
            }
           .ant-radio-inner{
                border-color: var(--color-brand-6);
                &::after{
                    background-color: var(--color-brand-6);
                }

            }
        }
    }
}

/* Target first radio button wrapper */
.ant-radio-button-wrapper:first-child {
    border-radius: 6px 0 0 6px;
    /* Adjust border-radius for the first element */
}

/* Target last radio button wrapper */
.ant-radio-button-wrapper:last-child {
    border-radius: 0 6px 6px 0;
    /* Adjust border-radius for the last element */
}
