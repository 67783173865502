.ant-btn {
  font-family: $font-family;
  // height: auto;
  // padding: 4px 12px;

  // border: 1px solid transparent;
  // border-color: transparent;

  // background: var(--color-white);
  // background: var(--color-gray-4);
  // color: var(--color-gray-8);
  border-radius: 6px;
  &.ant-btn-sm {
    border-radius: 4px;
  }

  &:disabled {
    color: #00000040 !important;
    text-shadow: none;
    box-shadow: none;
  }
  &.active {
    border-color: var(--color-brand-6);
    &:hover,
    &:active,
    &:focus {
      border-color: var(--color-brand-6);
    }
  }
  &.fa-button--truncate {
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      display: inline;
    }
    &.fa-button--truncate-sm {
      max-width: 250px;
    }
    &.fa-button--truncate-xs {
      max-width: 200px;
    }
    &.fa-button--truncate-lg {
      max-width: 450px;
    }
  }

  &.fa-button-ghost {
    background: transparent !important;
    border-color: transparent !important;
    outline: transparent !important;
    box-shadow: none !important;
    padding: 2px 8px !important;

    &:hover,
    &:focus,
    &:active {
      background: transparent !important;
      border-color: transparent !important;
      outline: transparent !important;
      box-shadow: none !important;
    }

    &:disabled {
      background: transparent !important;
      border-color: transparent !important;
      outline: transparent !important;
      box-shadow: none !important;
      svg {
        opacity: 0.5 !important;
      }
    }
  }

  &.ant-btn-background-ghost {
    box-shadow: none !important;
  }

  & {
    font-weight: $font-weight--bold;
    font-size: 14px;
    line-height: 1.5;
  }

  svg {
    display: inline;
  }
  svg ~ span {
    margin-left: 6px;
  }

  //anticon buttons
  span.anticon {
    svg {
      margin-right: 0px;
    }
  }
}

.ant-btn {
  &.flex {
    display: flex;
  }
  //Default Button
  &:focus,
  &:hover,
  &:active {
  }

  //Primary Button
  &.ant-btn-primary {
    color: var(--color-white);
    background: var(--color-brand-6);
    border-color: var(--color-brand-6);
    &:focus,
    &:hover,
    &:active {
      color: var(--color-white);
      background: var(--color-brand-5);
      border-color: var(--color-brand-5);
    }

    &:disabled {
      border-color: #d9d9d9 !important;
      background: #f5f5f5 !important;
    }

    &.ant-btn-dangerous {
      color: var(--color-white);
      background: var(--color-red-6);
      border-color: var(--color-red-6);
    }
  }

  &.ant-btn-secondary {
    color: var(--color-gray-8);
    background: var(--color-white);
    border-color: var(--color-gray-4);
  }

  &.ant-btn-plain {
    background-color: unset;
    padding: 0;
    box-shadow: none !important;
    &:focus,
    &:hover,
    &:active,
    &:visited {
      background: transparent !important;
      border-color: transparent !important;
      outline: transparent !important;
      box-shadow: none !important;
    }
  }

  //Dashed Button
  &.ant-btn-dashed {
    border-style: dashed;
  }

  //Text Button
  &.ant-btn-text {
    border-color: transparent;
    background: transparent;
    &:focus,
    &:hover,
    &:active {
      background-color: var(--color-gray-3);
    }
  }
  // ghost-primary button represents active button
  &.ant-btn-background-ghost.ant-btn-primary {
    color: var(--color-brand-6);
  }
  //Link Button == Secondary Button
  &.ant-btn.ant-btn-link {
    border-style: solid;
    border-color: transparent;
    color: var(--color-brand-6);
    background-color: var(--color-gray-4);
    &:focus,
    &:hover,
    &:active {
      opacity: 0.8;
      border-color: var(--color-brand-6) !important;
    }
  }
}

//Icon Alignment correction
.anticon {
  vertical-align: 0 !important;
}
.ant-tag-close-icon {
  vertical-align: 0.125em !important;
}
//commenting this because it breaks entire antd-large buttons.
// please use specific button-class if truncation is needed ++ Akhil
// .ant-btn-lg {
//     max-width: 300px;
//     line-height: 1;

//     span {
//         max-width: 250px;
//         text-overflow: ellipsis;
//         overflow: hidden;
//     }
// }

.btn-custom--bordered {
  background: #fff !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3) !important;
}

.btn-custom--radio-container {
  .ant-btn {
    background: #fff;
    border: 1px solid var(--color-gray-4);
    padding: 4px 8px;
    color: var(--color-gray-6);
    padding-left: 14px;
    padding-right: 14px;
    transition: all 0.3s;

    &:first-child {
      border-radius: 3px 0px 0px 3px;
      transition: all 0.3s;
      &.no-border {
        border-right-color: transparent;
      }
      &.active {
        // border-right: 1px;
        border: 1px solid var(--color-brand-6);
        color: var(--color-brand-6);
      }
    }
    &:last-child {
      border-radius: 0px 3px 3px 0px;
      &.no-border {
        border-left-color: transparent;
      }
      &.active {
        border: 1px solid var(--color-brand-6);
        color: var(--color-brand-6);
      }
    }
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  border-color: transparent !important;
  svg {
    opacity: 0.5;
  }
}
