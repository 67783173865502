.ant-checkbox-wrapper {
  &:hover,
  &:active,
  &:focus {
    .ant-checkbox-inner {
      border-color: var(--color-brand-6) !important;
    }
    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: var(--color-gray-5) !important;
      }
    }
  }
  .ant-checkbox {
    &.ant-checkbox-checked {
      &::after {
        border-color: var(--color-brand-6);
      }
      .ant-checkbox-inner {
        border-color: var(--color-brand-6);
        background-color: var(--color-brand-6);
      }
    }
    &.ant-checkbox-checked.ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: var(--color-gray-6);
        background-color: var(--color-gray-6);
        &::after {
          border-color: var(--color-white);
        }
      }
    }
  }
}

.multi-colored-checkbox-0 {
  .ant-checkbox-inner {
    border-color: var(--color-geekblue-4) !important;
    background-color: var(--color-geekblue-4) !important;
    &:hover {
      border-color: var(--color-geekblue-4) !important;
    }
  }
}

.multi-colored-checkbox-1 {
  .ant-checkbox-inner {
    border-color: var(--color-blue-5) !important;
    background-color: var(--color-blue-5) !important;
    &:hover {
      border-color: var(--color-blue-5) !important;
    }
  }
}

.multi-colored-checkbox-2 {
  .ant-checkbox-inner {
    border-color: var(--color-cyan-4) !important;
    background-color: var(--color-cyan-4) !important;
    &:hover {
      border-color: var(--color-cyan-4) !important;
    }
  }
}

.multi-colored-checkbox-3 {
  .ant-checkbox-inner {
    border-color: var(--color-green-4) !important;
    background-color: var(--color-green-4) !important;
    &:hover {
      border-color: var(--color-green-4) !important;
    }
  }
}

.multi-colored-checkbox-4 {
  .ant-checkbox-inner {
    border-color: var(--color-gold-3) !important;
    background-color: var(--color-gold-3) !important;
    &:hover {
      border-color: var(--color-gold-3) !important;
    }
  }
}

.multi-colored-checkbox-5 {
  .ant-checkbox-inner {
    border-color: var(--color-orange-5) !important;
    background-color: var(--color-orange-5) !important;
    &:hover {
      border-color: var(--color-orange-5) !important;
    }
  }
}

.multi-colored-checkbox-6 {
  .ant-checkbox-inner {
    border-color: var(--color-volcano-4) !important;
    background-color: var(--color-volcano-4) !important;
    &:hover {
      border-color: var(--color-volcano-4) !important;
    }
  }
}

.multi-colored-checkbox-7 {
  .ant-checkbox-inner {
    border-color: var(--color-red-4) !important;
    background-color: var(--color-red-4) !important;
    &:hover {
      border-color: var(--color-red-4) !important;
    }
  }
}

.multi-colored-checkbox-8 {
  .ant-checkbox-inner {
    border-color: var(--color-red-5) !important;
    background-color: var(--color-red-5) !important;
    &:hover {
      border-color: var(--color-red-5) !important;
    }
  }
}

.multi-colored-checkbox-9 {
  .ant-checkbox-inner {
    border-color: var(--color-brand-4) !important;
    background-color: var(--color-brand-4) !important;
    &:hover {
      border-color: var(--color-brand-4) !important;
    }
  }
}

.ant-checkbox-wrapper {
  color: var(--color-charcoal-85);
}
.ant-checkbox-disabled {
  .ant-checkbox-input  {
    border-color: var(--color-gray-5);
    background-color: var(--color-gray-3);
  }
  .ant-checkbox-inner::after {
    border-color: var(--color-gray-5);
    background-color: var(--color-gray-3);
  }
}
