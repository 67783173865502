
.fa-insights-box--spike
{
    position: absolute;
    top: 25px;
    right: 98.2%;
    transition: all 0.3s;
    .fa-insights-box--label
    {
        font-size: 10px;
        margin-top: -5px;
        transition: all 0.3s;
        opacity: 0;
    }
}

.fa-sub-insights-box--spike
{
    position: absolute;
    top: 25px;
    left: -20px;;
}
.fa-insights-box--highlight
{
    position: absolute;
    top: 0px;
    left: -5em;
    height: 100%;
}
.fa-insights--tab
{
    .ant-tabs-nav
    {
        margin: 0px;
        padding-left: 21%;
    }
}

// .fa-insights-box--type
// {
//     position: absolute;
//     top: 1rem;
//     left:4rem;
//     font-size: 12px !important;
//     transition: all 0.3s;
//     opacity: 0;
//     transform: translate3d(0, -10%, 0);
// }

.fa-insights-box--actions
{
    position: absolute;
    top: 25px;
    right: -50px;
    transition: all 1s;
    opacity: 0;
    transform: translate3d(0, -10%, 0);
}

// .fa-insights-box--animate{
//     transition: all 0.3s;
//     opacity: 0;
//     transform: translate3d(0, -10%, 0);
// }
.fa-insights-box--fixed-count{
    span{

        // display: none;
    }
}
.fa-insight-item--container, .fa-insight-item--sub-container
{
    &:hover{
        box-shadow: 0 10px 10px -10px var(--color-gray-4);
        .fa-insights-box--spike
        {
            .fa-insights-box--multiplier
            {
                color: rgba(var(--color-gray-10),1);
            }
            .fa-insights-box--label
            {
                opacity: 1;
            }
        }
    }

}
.fa-insight-item--sub-container
{
    &:hover{
        box-shadow: 0 10px 10px -10px var(--color-gray-4);
    }
}

.fa-insight-item{
    animation-name: fa-fadeInDown;
    animation-duration: 0.3s;

    &:hover{
        .fa-insights-box--fixed-count{
            width: 50px;
            span{
                display: none;
            }
        }
        .fa-insights-box--actions
        {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
}


.fa-insight-item--more {
    margin-bottom:10px;
    div{
        width: 100%;
        background-color: white;
        height:2px;
        margin-bottom:8px;
    }
}
.fa-custom-stroke-bg
{
    position: relative;
    .ant-progress-inner
    {
        background-color: transparent !important;
        .ant-progress-bg
        {
            background-color: transparent !important;
            background-image: url('https://s3.amazonaws.com/www.factors.ai/assets/img/product/progress-bar-fill-pattern.png') !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;

        }
    }

}
.fa-custom-progress-value
{

        position: relative;
        &::after{
            position: absolute;
            left: 102%;
            top:0;
            margin: auto 0;
            content: attr(value);
        }

}
.fa-progress
{
    .ant-progress-inner
    {
        // background-color: transparent !important;
    }
}


.progressArrow--extraline{
    position: relative;
    // &::after{
    //     content: "";
    //     border-left: 1px solid #B7BEC8;
    //     height: 170%;
    //     width: 5px;
    //     position: absolute;
    //     left: -17px;
    //     top: 0;

    // }
}

.fa-custom-badge
{
    .ant-badge-count
    {
        border-radius: 10px;
        padding: 1px 6px;
        height: auto;
        background-color: var(--color-gray-8);
        font-size: 14px;
        font-weight: bold;

    }
}


.fa-cdp--item{

    transition: all 0.3s;
    border-radius: 10px;
    .fa-cdp--action
    {
        opacity: 0;
        transition: all 0.3s;
        pointer-events: none;
    }
    &:hover{
        background-color: rgba(var(--color-gray-4),0.8);
        .fa-cdp--action
        {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.filter-block--row{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.filter-block--delete{
left:auto !important;
right:0 !important;
}

.filter-block--delete--mini{
left:auto !important;
right:auto !important;
position: relative !important;
padding: 0px !important;
    svg{
        height: 18px !important;
        width: 18px !important;
    }
}



.fa-vertical-scrolling-card{
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}


.insights-rating--container
{
    transition: all 1s;
    min-height: 45px;
    .insights-rating--block{
        display: none;
        transition: all 1s;
    }


    &:hover{
        .insights-rating--block{
            transition: all 1s;
            display: inline-flex;
            animation-name: fa-fadeInDown;
            animation-duration: 0.2s;
        }
    }
}
