.fa-select {
  .ant-select-selector {
    border: thin solid var(--color-brand-4) !important;
    border-radius: 6px !important;
  }

  &:hover,
  &:active,
  &:focus {
    .ant-select-selector {
      border-color: var(--color-brand-6) !important;
      box-shadow: none !important;
    }
  }
  &.ant-input-disabled {
    &:focus,
    &:hover,
    &:active {
      border: thin solid var(--color-brand-4) !important;
      box-shadow: none !important;
    }
  }
}

.fa-grp_noshadow {
  box-shadow: none !important;
}

.fa-grp_pos-btn {
  top: 34px !important;
  margin-left: 0;
}

.fa-select--group-select {
  animation-name: fa-fadeInDown;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;

  position: relative;
  width: 400px;
  max-height: 583px;
  padding: 15px 9px;
  background: #ffffff;
  box-shadow:
    0px 0px 4px 0px #00000040,
    0px -18px 8px 0px #ffffff inset;
  border-radius: 5px;
  z-index: 175;
  overflow-y: auto;
  overflow-x: hidden;

  .fa-select-group-select--options {
    padding: 10px 15px 10px 30px;
    color: var(--color-gray-8);
    font-weight: $font-weight--regular;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 4px;
    @apply rounded-md;

    &:hover,
    &:focus-visible {
      outline: none;
      background-color: var(--color-gray-3);
    }

    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: normal;
      white-space: nowrap;
      min-width: 48px;
      margin-bottom: 0;
    }
  }

  .fa-select--options {
    padding: 10px 15px;
    color: var(--color-gray-8);
    font-weight: $font-weight--regular;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 4px;
    @apply rounded-md;

    &:focus,
    &:focus-visible {
      background-color: var(--color-brand-1);
    }

    &:hover {
      background-color: var(--color-brand-1);
    }

    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: normal;
      white-space: nowrap;
      height: 22px;
      min-width: 48px;
    }
  }

  .fa-select--buttons {
    padding: 0px;
    color: var(--color-gray-8);
    font-weight: $font-weight--regular;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 4px;

    &:hover {
      background-color: var(--color-brand-1);
    }

    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: normal;
      height: 22px;
      min-width: 48px;
    }
  }

  .fa-select-group-select--content {
    display: flex;
    flex-direction: column;
  }
  .fa-select-group-select--option-group {
    padding: 20px 8px 12px 8px;
    border-bottom: 1px solid var(--color-gray-4);
    display: flex;
    justify-content: space-between;
    color: #5949bc;
    h4 {
      font-size: 14px;
      font-weight: 600;
      color: var(--color-gray-8);
      text-transform: capitalize;
    }
    div {
      display: flex;
    }
  }

  .ant-input-affix-wrapper {
    position: sticky;
    max-width: 374px;
    left: calc(50% - 374px / 2);
    top: 0px;
    background: #f5f6f8;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #eee !important;
    box-shadow: none !important;
    input.ant-input {
      height: 30px !important;
      background-color: #f5f6f8;
    }
    &:focus,
    &:hover,
    &:active {
      border: 1px solid #d9d9d9 !important;
      border-radius: 8px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3) !important;
      transition: all 0.3s;
    }
  }
}

.fa-select--group-select-sm {
  @extend .fa-select--group-select;
  width: 300px;
}

.fa-select--group-select-mini {
  @extend .fa-select--group-select;
  width: 232px;
  padding: 12px 8px;
}

.fa-search-select {
  padding-bottom: 4px;
}

.fa-select-ghost--highlight-text {
  .ant-select-selection-item {
    color: var(--color-brand-6);
  }
}
.fa-select-ghost--bold {
  .ant-select-selection-item {
    font-weight: bold;
    color: var(--color-gray-10);
  }
}

.group-select {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0px 9px;
  animation-name: fa-fadeInDown;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
  width: 400px;
  max-height: var(--height-var, 576px);
  background: #ffffff;
  box-shadow: 0px 6px 20px var(--color-gray-4);
  border-radius: 5px;
  z-index: 175;
  overflow-y: auto;
  overflow-x: hidden;

  &.placement-bottom {
    top: 0;
    box-shadow: 0px 6px 20px var(--color-gray-4);
  }

  &.placement-top {
    bottom: 32px;
    box-shadow: 5px -2px 20px var(--color-gray-4);
  }

  .ant-select-selector {
    border: thin solid var(--color-brand-4) !important;
    border-radius: 6px !important;
  }

  &:hover,
  &:active,
  &:focus {
    .ant-select-selector {
      border-color: var(--color-brand-6) !important;
      box-shadow: 0 0 0 2px rgba(var(--color-brand-6), 0.2) !important;
    }
  }

  &.ant-input-disabled {
    &:focus,
    &:hover,
    &:active {
      border: thin solid var(--color-gray-4) !important;
      box-shadow: none !important;
    }
  }

  .ant-input-affix-wrapper {
    position: sticky;
    max-width: 374px;
    left: calc(50% - 374px / 2);
    top: 0px;
    background: #f5f6f8;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #eee !important;
    box-shadow: none !important;

    input.ant-input {
      height: 30px !important;
      background-color: #f5f6f8;
    }

    &:focus,
    &:hover,
    &:active {
      border: 1px solid #d9d9d9 !important;
      border-radius: 8px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3) !important;
      transition: all 0.3s;
    }
  }

  &__input {
    position: sticky;
    top: 0;
    padding-top: 15px;
    background-color: white;
    z-index: 10;
  }

  &__content {
    padding-top: 8px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .group {
      display: flex;
      flex-direction: column;
      padding: 0;

      &__header {
        padding: 12px 8px;
        border-bottom: 1px solid var(--color-gray-4);
        display: flex;
        justify-content: space-between;
        color: #5949bc;

        h4 {
          font-size: 14px;
          font-weight: 900;
          color: var(--color-gray-8);
          text-transform: capitalize;
        }

        div {
          display: flex;
        }
      }

      &__options {
        display: flex;
        flex-direction: column;

        .option {
          padding: 12px 15px 12px 30px;
          color: var(--color-gray-8);
          font-weight: $font-weight--regular;
          cursor: pointer;
          display: inline-flex;

          &:hover {
            background-color: var(--color-brand-1);
          }

          h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: normal;
            height: 22px;
            min-width: 48px;
          }
        }
      }
    }
  }

  &__additionalAction {
    position: sticky;
    bottom: 0;
    padding: 9px 0 0 0;
    background-color: white;
    z-index: 10;
  }

  &__hd_overlay {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 10;
  }
}

.show-hide-btn {
  color: #5949bc;
  padding-left: 0px;
  margin-left: 30px;
  // margin-bottom: 10px;
  text-align: left !important;
  font-weight: normal !important;

  &:hover,
  &:focus {
    background: #fff !important;
    color: #5949bc !important;
  }
}

.fa-select-freqCap {
  .ant-select-selection-search {
    height: 30px !important;
  }
}

.ant-select.ant-select-multiple {
  .ant-select-selector {
    min-height: 32px;
  }
  &.ant-select-lg .ant-select-selector {
    min-height: 40px;
    border-radius: 6px;
  }
  &.ant-select-sm .ant-select-selector {
    min-height: 24px;
  }
}
