.fa-dashboard--widget-card {
  background-color: var(--color-white);
  border-radius: 8px;
  overflow: auto;
  border: thin solid var(--color-gray-4);
  transition: all 0.3s;

  .fa-widget-card--top-actions {
    transition: all 0.3s;
    opacity: 0;
  }

  &:hover {
    border: thin solid var(--color-brand-6);
    box-shadow: 0px 0px 8px var(--color-gray-4);
    .fa-widget-card--top-actions {
      opacity: 1;
    }
    .widget-card--expand-icon {
      visibility: visible;
    }
  }
  .widget-card--title-container {
    &:hover {
      .widget-card--title {
        text-decoration: underline !important;
      }
      .widget-card--expand-icon {
        transform: scale(1.1);
      }
    }
  }
  .widget-card--expand-icon {
    visibility: hidden;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }

  .fa-widget-card--legend {
    padding: 0.5em 2em;
    border-top: thin solid var(--color-gray-4);
    border-bottom: thin solid var(--color-gray-4);

    .ant-badge {
      margin-right: 12px;

      .ant-badge-status-dot {
        width: 12px;
        height: 12px;
      }

      .ant-badge-status-text {
        font-size: 12px;
        margin-left: 3px;
        color: var(--color-gray-7);
      }
    }
  }

  .fa-widget-card--visuals {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.fa-dashboard--widget-card::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}

.fa-dashboard--widget-card::-webkit-scrollbar-track {
  background-color: transparent;
}

.fa-dashboard--widget-card::-webkit-scrollbar-thumb {
  background-color: #7c7c7c;
  border-radius: 8px;
}

.fa-dasboard-privacy--card {
  border: thin solid var(--color-gray-4);
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    border: thin solid var(--color-brand-6);
    background-color: var(--color-brand-1);
  }
}

.fa-dasboard-privacy--card.selected {
  border: thin solid var(--color-brand-6);
  background-color: var(--color-brand-1);
}

.fa-widget-card--resize-container {
  // margin: auto;
  position: relative;
  right: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // transition: all 0.3s;
  visibility: hidden;
  display: flex;
  align-items: center;

  .fa-widget-card--resize-contents {
    background-color: var(--color-brand-6);
    border-radius: 8px;
    padding: 5px 2px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // transition: all 0.3s;

    a {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: var(--color-white);
      transition: all 0.3s;
      opacity: 0.6;
      padding: 5px 2px;

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }
    }
  }
}

.widget-card-top-div {
  min-height: 370px;
  max-height: 370px;
  height: 370px;
  &:hover {
    .fa-widget-card--resize-container {
      visibility: visible;
    }
  }
}

.fa-global-search--input {
  background-color: var(--color-gray-9);
  border: none;
  border-radius: 6px;
  width: 232px;
  height: 36px;
  box-shadow: none;
  transition: all 0.2s;
  padding: 6.5px 11px;
  &:hover {
    padding: 3px 22px 3px 18px;
    border: none;
    cursor: pointer;
  }
  input {
    background-color: transparent;
    ::-webkit-input-placeholder {
      text-align: center !important;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      text-align: center !important;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      text-align: center !important;
    }

    :-ms-input-placeholder {
      text-align: center !important;
    }
  }
}

.fa-global-search--input-fw {
  width: 100%;
  &:hover {
    width: 100% !important;
  }
}

.fa-global-search--input-bgw {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-10);

  &:hover {
    border: 1px solid var(--color-gray-10);
  }
}

.fa-global-search--modal {
  .ant-modal-content {
    max-height: 600px;
    overflow: hidden;
    border-radius: 8px !important;
    margin-top: 12px;
    // box-shadow: 2px 2px 8px var(--color-gray-6) !important;

    .fa-global-search--contents {
      max-height: 500px;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

.fa-dashboard--no-data-container {
  margin: 0 auto;
  margin-top: 7em;
  max-width: 500px;
  text-align: center;
  img {
    width: 100%;
    max-width: 250px;
  }
}
.fa-no-data--img {
  img {
    width: 100%;
    max-width: 100px;
  }
}

.fa-title--editable {
  padding: 2px 8px;
  transition: all 0.3s;
  border-radius: 4px;
  &:hover {
    background-color: var(--color-gray-4);
  }
}
