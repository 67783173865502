// Scrollbar variables
$scrollbar-track-color: rgba(0, 0, 0, 0.03);
$scrollbar-thumb-color: rgba(0, 0, 0, 0.4);
$scrollbar-thumb-radius: 10px;

// Scrollbar mixin
@mixin scrollbar-styles {
  scrollbar-width: none; // Firefox: Thin scrollbar
  scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;
  -ms-overflow-style: -ms-autohiding-scrollbar; // Internet Explorer 11+ on Windows
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none; // Hide scrollbar by default in WebKit browsers
  }

  &:hover {
    scrollbar-width: auto; // Show scrollbar on hover in Firefox

    &::-webkit-scrollbar {
      display: block; // Show scrollbar on hover in WebKit browsers
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $scrollbar-thumb-radius; // Rounded scrollbar thumb
    background-color: $scrollbar-thumb-color;
  }
}

@mixin no-scroll {
  overflow: hidden; // No scrolling
}

@mixin thin-scrollbar {
  scrollbar-width: thin;
  &:hover {
    scrollbar-width: thin;
  }
}
.scrollable {
  // set both max-height and max-width to see the scrollbar on both axis and add modifier class 'scrollable--auto-xy'
  // - set only max-height to see the scrollbar on y-axis add modifier class 'scrollable--auto-y'

  // Vertical scrolling
  &--auto-y {
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar-styles;

    &-thin {
      @extend .scrollable--auto-y;
      @include thin-scrollbar;
    }
  }

  // Both axes scrolling
  &--auto-xy {
    overflow: auto;
    @include scrollbar-styles;

    &-thin {
      @extend .scrollable--auto-xy;
      @include thin-scrollbar;
    }
  }

  // Disable scrolling
  &--no-scroll {
    @include no-scroll;
  }
}

.scrollbar-styles-only {
  @include scrollbar-styles;
}

// for antd
.ant-table-content {
  @include scrollbar-styles;
  overscroll-behavior: auto;
}
