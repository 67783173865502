.fai-text {
  font-family: $font-family;
  color: var(--color-gray-10);

  //Case
  &.fai-text__transform--uppercase {
    text-transform: uppercase;
  }
  &.fai-text__transform--lowercase {
    text-transform: lowercase;
  }

  //Size
  &.fai-text__size--h1 {
    font-size: 2.5rem;
  }
  &.fai-text__size--h2 {
    font-size: 2rem;
  }
  &.fai-text__size--h3 {
    font-size: 1.5rem;
  }
  &.fai-text__size--h4 {
    font-size: 1.25rem;
  }
  &.fai-text__size--h5 {
    font-size: 1.125rem;
  }
  &.fai-text__size--h6 {
    font-size: 1rem;
  }
  &.fai-text__size--h7 {
    font-size: 1rem;
  }
  &.fai-text__size--h8 {
    font-size: 0.875rem;
  }

  // .fai-text__size--p{}

  //Weight
  &.fai-text__weight--thin {
    font-weight: $font-weight--thin;
  }
  &.fai-text__weight--normal,
  &.fai-text__weight--regular {
    font-weight: $font-weight--regular;
  }
  &.fai-text__weight--medium {
    font-weight: $font-weight--medium;
  }
  &.fai-text__weight--bold {
    font-weight: $font-weight--bold;
  }

  //Line Height
  &.fai-text__line-height--normal {
    line-height: 1.6;
  }

  &.fai-text__line-height--small {
    line-height: 1;
  }

  &.fai-text__line-height--medium {
    line-height: 1.375;
  }

  &.fai-text__line-height--large {
    line-height: 2;
  }

  //Alignment
  &.fai-text__align--left {
    text-align: left;
  }
  &.fai-text__align--right {
    text-align: right;
  }
  &.fai-text__align--center {
    text-align: center;
  }
}

a.fa-anchor {
  color: var(--color-brand-6);
  text-decoration: underline;
  font-weight: $font-weight--bold;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: var(--color-brand-6);
  }
}
