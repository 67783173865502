.signup-container{
    height: calc(100% - 75px);
}
.login-container{
    padding: 5em 2em; 
    height: 100vh;
    .desc-text,.ant-form-item {
        width: 50%;
    }
    .ant-form-item {
        margin:0
    }
    .fa-input-50,.fa-button-50{
        width: 50%;
    }
}
.fa-single-screen--illustration
{
    position: absolute;
    right: 1.5em;
    bottom: 1.5em;
}


.fa-line--vertical{ 
    position: relative; 

width: 2px; /* line-width */
height: 30px; /* line length */
margin-left: 50%;
margin-top: 6px;
margin-bottom: 6px;
float: left;
background: #1890FF;

    &::after,&::before{
        content: "";
        position: absolute;
        width: 0px;
        height: 0px;
        border: 5px solid transparent;
    }

    &::after {
        top: -10px;
        border-bottom: 10px solid #1890FF;
        left: -4px;
    }
    &::before{
        bottom: -10px;
        border-top: 10px solid #1890FF;
        left: -4px;
    }
 
    
}

.fa-workflow_section--line{
    height: 32px;
    width: 2px;
    background-color:  #1890FF;
    transition: border-color 0.3s ease-out 0s;
    margin-top: -8px;
    margin-bottom: -8px;
}
.fa-workflow_section--dot{
        width: 14px;
        height: 14px;
        border-radius: 50%; 
        position: relative;
        z-index: 1;

    

        &::after {
            content: "";
            position: absolute;
            top: 4px;
            left: 3px;
            width: 8px;
            height: 8px;
            background-color: #1890FF;
            border-radius: 50%;
            transition: background-color 0.3s ease-out 0s;
            
        }
        &::before{
            content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: 12px;
        height: 12px;
        // background-color: var(--bgColor-default, var(--color-canvas-default, #0d1117));
        background-color: transparent;
        border-radius: 50%;
        } 

        &.top{
            margin-top: -8px;
            margin-bottom: 0px;
        }
        &.bottom{
            margin-bottom: -8px;
            margin-top: 0px;
        }
    
}