.fa-content-container
{
    margin-left: 64px;
}

.fa-content-container.no-sidebar
{
    margin-left: 0;
}

.fa-content-container--full-height{
    min-height: 100vh;
}