
.ant-drawer.ant-drawer-left.ant-drawer-open{

    &.fa-drawer { 
        // left: 63px;
        // z-index: 1005;
        .ant-drawer-body
        {
            padding: 0;
        }
    }

    .ant-drawer-header
    {
        padding: 24px 21px 24px 42px;
    }
}