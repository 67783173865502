.fa-input {
  border: thin solid var(--color-gray-4);
  border-radius: 6px;

  &:focus,
  &:hover,
  &:active {
    border-color: var(--color-brand-6);
    box-shadow: 0 0 0 2px rgba(var(--color-brand-6), 0.2);
  }

  &.ant-input-disabled {
    &:focus,
    &:hover,
    &:active {
      border: thin solid var(--color-gray-4) !important;
      box-shadow: none !important;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e6f7ff;
}

.ant-select-item.ant-select-item-option {
  padding: 8px !important;
}

.ant-select-item-option-state {
  display: none;
}

.ant-select-dropdown {
  padding: 0 !important;
}

.ant-select-selector {
  outline: unset !important;
}
.ant-select-multiple .ant-select-selector::after{
  content: none !important;
}

.editable-header-title {
  &.ant-typography-edit-content {
    min-width: 400px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ant-input-clear-icon {
  margin-top: -2px;
}
