.ant-table--custom
{
    // table head
    // .ant-table-thead
    // {
    //     .ant-table-cell
    //     {
    //         background-color: transparent;
    //         border-right: thin solid var(--color-gray-4);
    //         border-bottom: thin solid var(--color-gray-6);
    //         font-weight: $font-weight--bold;
    //         font-size: 12px;
    //         padding: 10px 16px;
    //         &:last-of-type{
    //                 border-right: thin solid transparent;

    //         }
    //     }
    // }
    // // table body
    // .ant-table-tbody
    // {
    //     .ant-table-cell
    //     {
    //         border-bottom: thin solid var(--color-gray-4);
    //         font-weight: $font-weight--regular;
    //         font-size: 16px;
    //     }

    // }
}

.fa-table--basic
{
    // table head
    .ant-table-thead
    {
        .ant-table-cell
        {
            font-weight: $font-weight--bold;
            font-size: 12px;
            padding: 11px 12px;
            color: var(--color-gray-8);
        }
        .ant-table-column-sorters{
            padding: 0px 0px;
        }
    }
    // table body
    .ant-table-tbody
    {
        .ant-table-cell
        {
            border-bottom: thin solid var(--color-gray-4);
            font-weight: $font-weight--regular;
            font-size: 14px;
            padding: 10px 12px;
            color: var(--color-gray-10);
        }

    }
}
