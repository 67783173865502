.fa-modal--full-width {
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100% !important;
  padding-bottom: 0;
  margin: 0 !important;
  transform-origin: 0 !important;
  overflow: hidden !important;
  background-color: var(--color-white);

  .ant-modal-content {
    background-color: var(--color-white);
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-modal-body {
    padding: 0;
  }

  .fa-modal--header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--color-white);
    z-index: 20;
    // border-bottom: thin solid var(--color-gray-4);
    box-shadow: 0px 0px 8px rgba(var(--color-gray-4), 1);
  }

  .fa-modal--header + .fa-container {
    margin-top: 75px;
  }
}

.fa-modal--regular {
  &.fa-modal--slideInDown {
    animation-name: fa-fadeInDown;
    animation-duration: 0.2s;
  }

  &.fa-modal--slideInUp {
    animation-name: fa-slideInUp;
    animation-duration: 0.2s;
  }

  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  .ant-modal-footer {
    background: white;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.fa-modal-header--no-padding {
  .ant-modal-header {
    padding: 0;
  }
}

.fa-modal-body--no-padding {
  .ant-modal-body {
    padding: 0;
  }
}

.fa-modal-body--custom-scrollable {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 5em;
}

.fa-modal-body--custom-scrollable-1 {
  max-height: calc(80vh - 48px);
}

//global
.ant-modal-close {
  outline: none !important;
}

.fa-notification--zindex {
  z-index: 3000 !important;
}

.ant-modal-footer {
  .ant-btn:disabled {
    background: var(--color-gray-6);
    color: var(--color-white);
    font-weight: 600;
    border: none;
    opacity: 1;
  }
}

.fa-modal--quick-view {
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100% !important;
  padding: 30px 32px;
  transform-origin: 0 !important;
}

.ant-message-notice-content,
.ant-modal-content {
  border-radius: 8px;
}
.ant-tooltip-inner,
.ant-popover-inner {
  border-radius: 8px;
}
