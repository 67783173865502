.fa-component-color--brand-1 {
  background-color: var(--color-brand-1);
}
.fa-component-color--brand-2 {
  background-color: var(--color-brand-2);
}
.fa-component-color--brand-3 {
  background-color: var(--color-brand-3);
}
.fa-component-color--brand-4 {
  background-color: var(--color-brand-4);
}
.fa-component-color--brand-5 {
  background-color: var(--color-purple-5);
}
.fa-component-color--brand-6 {
  background-color: var(--color-brand-6);
}
.fa-component-color--brand-7 {
  background-color: var(--color-brand-6);
}
.fa-component-color--brand-8 {
  background-color: var(--color-brand-8);
}
.fa-component-color--brand-9 {
  background-color: var(--color-brand-9);
}
.fa-component-color--brand-10 {
  background-color: var(--color-brand-10);
}

.fa-component-color--mono-1 {
  background-color: var(--color-gray-4);
}
.fa-component-color--mono-2 {
  background-color: var(--color-gray-4);
}
.fa-component-color--mono-3 {
  background-color: var(--color-gray-6);
}
.fa-component-color--mono-4 {
  background-color: var(--color-gray-7);
}
.fa-component-color--mono-5 {
  background-color: var(--color-gray-8);
}
.fa-component-color--mono-6 {
  background-color: var(--color-gray-10);
}
.fa-component-color--mono-7 {
  background-color: var(--color-gray-11);
}
.fa-component-color--mono-8 {
  background-color: var(--color-gray-12);
}
.fa-component-color--mono-9 {
  background-color: var(--color-gray-12);
}
.fa-component-color--mono-10 {
  background-color: var(--color-gray-12);
}

.fa-component-color--fn-red {
  background-color: var(--color-red-5);
}
.fa-component-color--fn-orange {
  background-color: var(--color-volcano-4);
}
.fa-component-color--fn-green {
  background-color: var(--color-green-6);
}

.fa-component-color--box {
  height: 55px;
  width: 55px;
}

.fa-demo-grid--modal {
  top: 0;
  left: 64px !important;
  height: 100%;
  width: calc(100vw - 64px) !important;
  padding-bottom: 0;
  margin: 0 !important;
  transform-origin: 0 !important;
  overflow: hidden !important;

  .ant-modal-content {
    background-color: transparent;
    height: 100%;
  }
  .ant-modal-body {
    padding: 0;
  }
}
.fa-demo-grid--col {
  background-color: #002e6f12;
  // padding: 24px;
  // &.even{
  //     background-color: #2b01011f;
  // }
  .fa-demo-grid--col-content {
    width: 100%;
    background-color: #ea62622e;
    height: calc(100vh - 55px);
  }
}

.fa-icon--container {
  background: #ffffff;
  border: 1px solid #f5f6f8;
  box-sizing: border-box;
  border-radius: 12px;
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  }
  p {
    font-size: 10px;
    font-weight: $font-weight--regular;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 1;
  }
}

.fa-tag--green {
  color: var(--color-gray-8) !important;
  background: rgba(var(--color-green-6), 0.1) !important;
  border-color: var(--color-green-6) !important    ;
}

.fa_input_text_container {
  .ant-select-selector {
    height: 32px !important;
    line-height: 0 !important;
    border-radius: 0 !important;
    min-width: 100px !important;
  }
  .ant-select-selection-item {
    height: 24px !important;
    line-height: 22px !important;
  }
  .ant-select-selection-item-content {
    max-width: 100px !important;
  }
  .ant-select-selection-search {
    min-width: 32px !important;
  }
}

.overview-container {
  max-height: calc(100vh - 208px);
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .overview {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 16px 0;

    .top-metrics {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      .metric {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1 1 auto;
        height: 96px;
        width: calc(25% - 16px);
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
      }
    }

    .trend {
      border: 1px solid #f0f0f0;
      border-radius: 8px;

      .heading {
        display: flex;
        padding: 16px;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #f0f0f0;
      }

      .chart {
        padding: 16px 24px 8px 8px;
      }
    }

    .top-tables {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      gap: 20px;

      .top-table-container {
        flex: 1 1 auto;
        max-width: calc(50% - 10px);
        max-height: 288px;
        border: 1px solid #f0f0f0;
        border-radius: 8px;

        .heading {
          display: flex;
          padding: 16px;
          align-items: center;
          height: 48px;
          border-bottom: 1px solid #f0f0f0;
          background-color: transparent;
        }

        .content {
          width: 100%;
          padding: 0;

          .ant-table-thead {
            .ant-table-cell {
              font-weight: $font-weight--medium;
              font-size: 12px;
              color: var(--color-gray-8);
              padding: 8px 12px;
              white-space: nowrap;
            }
          }

          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                border: none;
                font-weight: $font-weight--regular;
                font-size: 12px;
                line-height: 24px;
                padding: 12px;
                color: var(--color-gray-10);
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
